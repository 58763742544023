export enum FindingName {
  CHANGE = "Change",
  INGRESS_CHANGE = "IngressChange",
  IMAGE_PULL_BACKOFF_REPORTER = "image_pull_backoff_reporter",
  IMAGE_PULL_BACKOFF = "ImagePullBackoff", // same as: image_pull_backoff_reporter
  POD_OOM_KILLER_ENRICHER = "pod_oom_killer_enricher",
  POD_OOM_KILLED = "PodOOMKilled", // same as: pod_oom_killer_enricher
  REPORT_CRASH_LOOP = "report_crash_loop",
  CRASH_LOOP_BACKOFF = "CrashLoopBackoff", // same as: report_crash_loop
  KUBERNETES_WARNING_EVENT = "Kubernetes Warning Event",
  KUBERNETES_WARNING_EVENT_V2 = "KubernetesWarningEvent", // same as: Kubernetes Warning Event
  GENERIC_FINDING = "Generic Finding",
  JOB_FAILURE_LOG = "Job Failure Log",
  POD_LIFECYCLE_WARNING = "PodLifecycleWarning",
  NODE_HEALTH_WARNING = "NodeHealthWarning",
  PROBE_FAILURE = "ProbeFailure",
  POLICY_VIOLATION = "PolicyViolation",
  SCALE_WARNING = "ScaleWarning",
  SCHEDULING_WARNING = "SchedulingWarning",
  VOLUME_WARNING = "VolumeWarning",
}

export enum FindingType {
  FIRING_HIGH = "FIRING_HIGH",
  FIRING_MEDIUM = "FIRING_MEDIUM",
  FIRING_LOW = "FIRING_LOW",
  FIRING_INFO = "FIRING_INFO",
  RESOLVED = "RESOLVED",
  CHANGE_UPDATE = "CHANGE_UPDATE",
  CHANGE_CREATE = "CHANGE_CREATE",
  CHANGE_DELETE = "CHANGE_DELETE",
  K8S = "K8S"
}

