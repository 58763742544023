export interface IFiringAlert {
	id: string
	finding_name: string
}

export function groupFiringAlerts(firingAlerts) {
	return Object.entries(
		firingAlerts.reduce((groups, alert) => {
			groups[alert.name] = groups[alert.name] ?? [];
			groups[alert.name].push(alert);
			return groups
		}, {}) as Record<string, IFiringAlert[]>
	).map((group) => {
		const name = group[0]
		const count = group[1].length
		return { name, count }
	})
}

export function createFiringAlertHashmap(alerts, clusterKey, secondKey) {
	return alerts.reduce((hashmap, alert) => {
		const { [clusterKey]: cluster, [secondKey]: serviceKey } = alert

		if(!cluster || !serviceKey)
			return hashmap

		if(!hashmap[cluster])
			hashmap[cluster] = {}

		if(!hashmap[cluster][serviceKey])
			hashmap[cluster][serviceKey] = []

		hashmap[cluster][serviceKey].push(alert)

		return hashmap
	}, {})
}
