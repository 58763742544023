import { Action } from "@/notifications/actions/types"
import { TNotificationIcons } from "@/components/notifications/types";

export enum ErrorTypes {
	ALERT_MANAGER_CONNECTION = 'alertManagerConnection',
	PROMETHEUS_CONNECTION = 'prometheusConnection',
	PROMETHEUS_RETENTION_TIME = 'prometheusRetentionTime',
	RELAY_CONNECTION = 'relayConnection'
}

export enum NotificationType {
  ERROR = "error",
  WARNING = "warning"
}

/**
 * Contains all notification details & action item for the user.
 */
export type Notification = {
  cluster?: string
  msg: string
  action: Action
	secondaryAction?: Action
  type: NotificationType
	icon?: TNotificationIcons
	group?: string
}

export type ErrorNotification = Notification & { type: NotificationType.ERROR }
