/**
 * Relay could not authenticate the request to run a manual action.
 */
import { ManualActionRequest } from "@/manual-actions/types"

export class RelayAuthError extends Error {
  constructor() {
    super()
    Error.captureStackTrace && Error.captureStackTrace(this, RelayAuthError)
    this.name = "RelayAuthError"
  }
}

/**
 * Relay could not communicate with the clusters.
 */
export class ClustersNotFoundError extends Error {
  static __id__ = Symbol()
  clusters?: string[]
  constructor(clusters?: string | string[]) {
    super()
    Error.captureStackTrace && Error.captureStackTrace(this, ClustersNotFoundError)
    this.name = "ClustersNotFoundError"
    this.clusters = typeof(clusters) === "string" ? [clusters] : clusters
  }
}

/**
 * Relay returned an error.
 */
export class ManualActionFailedError extends Error {
  static __id__ = Symbol()
  public request: ManualActionRequest
  public error_code?: number
  public error_message?: string

  constructor(request: ManualActionRequest, code?: number, msg?: string) {
    super()
    Error.captureStackTrace && Error.captureStackTrace(this, ManualActionFailedError)
    this.name = "ManualActionFailedError"
    this.request = request
    this.error_code = code
    this.error_message = msg
  }
}

/**
 * The client could not parse the manual action response.
 */
export class ManualActionParsingError extends Error {
  constructor(msg: string) {
    super(msg)
    Error.captureStackTrace && Error.captureStackTrace(this, ManualActionParsingError)
    this.name = "ManualActionParsingError"
  }
}

/**
 * We expect the evidence to be in a specific place in the response, but it's not there.
 */
export class FailedToGetEvidenceFromResponseError extends Error {
  constructor(findingIndex: number, evidenceIndex: number) {
    super(`Evidence was expected to be at data.findings[${findingIndex}].evidence[${evidenceIndex}]`)
    Error.captureStackTrace && Error.captureStackTrace(this, FailedToGetEvidenceFromResponseError)
    this.name = "FailedToGetEvidenceFromResponseError"
  }
}
