import { Provider } from "@supabase/supabase-js"
import supabase from "@/store/supabase-client"
import { ENABLE_AZURE_LOGIN, ENABLE_GOOGLE_LOGIN, ENABLE_KEYCLOAK_LOGIN } from "@/consts"
import { trackError, ErrorName } from '@/utils/track-error';

export class SSOProvider {
  constructor(
    public readonly id: Provider,
    public readonly displayName: string,
    public readonly iconName: string,
    public readonly scopes: string) {
  }
}

const GOOGLE   = new SSOProvider("google", "Google", "google", "email")
const AZURE    = new SSOProvider("azure", "Azure", "azure", "email")
const KEYCLOAK = new SSOProvider("keycloak", "Keycloak", "keycloak", "email,openid")

export class AuthService {
  static async loginWithSSO(provider: Provider, scopes: string, redirectTo?: string) {
    const response = await supabase.client.auth.signIn({ provider }, {
      scopes,
      redirectTo: redirectTo || window.location.origin
    })
    if(response.error) {
      trackError(ErrorName.FAILED_TO_LOGIN_WITH_SSO)
      throw new Error("Failed to login with SSO!")
    }
    return response;
  }

  static async loginWithEmail(email: string, password: string, redirectTo?: string) {
    if(!email || !password)
      throw new Error("Both email and password fields are required")

    const { error } = await supabase.client.auth.signIn({ email, password })
    if(error) {
      trackError(ErrorName.FAILED_TO_LOGIN_WITH_EMAIL)
      throw new Error("Failed to login!")
    }
    window.location.href = redirectTo || window.location.origin
  }

  static async signUpWithEmail(email: string, password: string) {
    if(!email || !password)
      throw new Error("Both email and password fields are required")

    const { error } = await supabase.client.auth.signUp({ email, password })
    if(error) {
      trackError(ErrorName.FAILED_TO_SIGNUP_WITH_EMAIL)
      throw new Error("Failed to sign up!")
    }
    window.location.href = window.location.origin
  }

  static async logout() {
    const { error } = await supabase.client.auth.signOut()
    if(error) {
      trackError(ErrorName.FAILED_TO_LOGOUT)
      throw new Error("Failed to logout!")
    }
    window.location.href = window.location.origin
  }

  static getEnabledSSOProviders(): SSOProvider[] {
    const providers: SSOProvider[] = []

    if(ENABLE_GOOGLE_LOGIN)
      providers.push(GOOGLE)
    if(ENABLE_AZURE_LOGIN)
      providers.push(AZURE)
    if(ENABLE_KEYCLOAK_LOGIN)
      providers.push(KEYCLOAK)

    return providers
  }
}