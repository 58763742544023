const k8sMemoryFactors = {
	"m": 1 / 1000,  // milli
	"u": 1 / (1000 * 1000),  // micro
	"n": 1 / (1000 * 1000 * 1000),  // nano
	"K": 1000,
	"M": 1000 * 1000,
	"G": 1000 * 1000 * 1000,
	"P": 1000 * 1000 * 1000 * 1000,
	"E": 1000 * 1000 * 1000 * 1000 * 1000,
	"k": 1024,
	"Ki": 1024,
	"Mi": 1024 * 1024,
	"MB": 1024 * 1024,
	"Gi": 1024 * 1024 * 1024,
	"Pi": 1024 * 1024 * 1024 * 1024,
	"Ei": 1024 * 1024 * 1024 * 1024 * 1024,
}

export const formatNumberInMemory = (number: number) => `${number} MB`

export const formatUnitsToNumber = (value: string): number | undefined => {
	if (!value) return undefined

	const digits = parseInt(value)
	const unit = value.replace(/\d+/g, '');

	if (!unit) return digits;

	const measuredUnit = k8sMemoryFactors[unit]
	return digits * measuredUnit
}

export const formatNumberToUnits = (value: number, suffix = 'MB', convert = true): string | undefined => {
	if (!value || isNaN(value)) return undefined

	const finalValue = convert ? Math.round(value / k8sMemoryFactors[suffix]) : value
	return `${finalValue}${suffix}`
}
