// @ts-ignore
import { DateTime } from "luxon"
import { FindingV2, FIRING_ALERT_WINDOW_HOURS } from "@/store/findings/v2/types"

export function getMinFiringDate(): Date {
  return DateTime.now().minus({ hours: FIRING_ALERT_WINDOW_HOURS }).toJSDate()
}

export function createAlertCounts(findings: FindingV2[]) {
  return Object.entries(
    findings.reduce((map, alert) => {
      if(!map.hasOwnProperty(alert.nameCorrected))
        map[alert.nameCorrected] = 0
      map[alert.nameCorrected] += 1
      return map
    }, {} as Record<string, number>)
  )
    .map(([name, count]) => ({ name, count }))
    .sort((a, b) => b.count - a.count || a.name.localeCompare(b.name))
}
