import supabase from "@/store/supabase-client";
import { IDBQueryLabel, IManualActionQueryLabel, useMetricCollector } from "@/composables/use-metric-collector";
const { addMetric, initGathering, beforeUnloadListener } = useMetricCollector()

initGathering()

function roundUpToNearest(num: number) {
  const magnitude = Math.pow(10, Math.floor(Math.log10(num)) + 1);
  return Math.ceil(num / magnitude) * magnitude;
}

window.addEventListener("beforeunload", beforeUnloadListener)

window.fetch = new Proxy(window.fetch, {
  apply(actualFetch, that, args) {
    // Forward function call to the original fetch
    const result = Reflect.apply(actualFetch, that, args);

    const requestUrl = args[0]
    const escapeBaseUrls = (url: string) => url.replace(/\./g, '\\.')
    const supabaseRegex = new RegExp(`^${escapeBaseUrls((window as any).ENV_SUPABASE_URL)}`)
    const relayRegex = new RegExp(`^${escapeBaseUrls((window as any).ENV_RELAY_HOST)}\\\/integrations\\\/(sync|async)\\\/actions`)

    const isRelayRequest = requestUrl.match(relayRegex)
    const isDBRequest = requestUrl.match(supabaseRegex)

    if ((!isDBRequest && !isRelayRequest) || args[1].method === 'HEAD') {
      return result
    }

    const account_id = supabase.accountId
    const user_id = supabase.userId
    const startTime = performance.now()

    const supabaseTableName = isDBRequest
      ? requestUrl.match(new RegExp(`${supabaseRegex.source}\\\/rest\\\/v1\\\/(rpc\\\/)?(\\w+)`))[2]
      : null

    result.then((response) => {
      if(response.headers.get('content-type')?.includes('application/json')) {
        const endTime = performance.now()
        const execTime = endTime - startTime
        const action_name = args[1].body && JSON.parse(args[1].body)?.body?.action_name

        response.clone().json().then(data => {
          addMetric({
            account_id,
            user_id,

            value: execTime,
            name: isDBRequest ? "db_query_millis" : "manual_actions_query_millis",

            labels: {
              ...(isDBRequest ? { returned_records_count: Array.isArray(data) ? roundUpToNearest(data.length) : 1 } : [] ),
              ...(isDBRequest ? { table_name: supabaseTableName } : [] ),
              ...(isDBRequest ? { query_duration_hours: 'none' } : []),
              ...(isDBRequest ? { filters: 'none' } : [] ),

              ...(isRelayRequest ? { action_name } : [] ),
              ...(isRelayRequest ? { extra_info: 'none' } : [] ),
            } as Omit<IManualActionQueryLabel, 'page_name'> | Omit<IDBQueryLabel, 'page_name'>
          })
        })
      }
    })
      .catch(() => {
        const error_name = isDBRequest
          ? 'DB_QUERY_ERROR' : isRelayRequest
            ? 'MANUAL_ACTION_ERROR' : 'none'

        addMetric({
          account_id,
          user_id,

          name: 'technical_errors',
          value: 1,

          labels: {
            error_type: 'exception',
            error_name
          }
        })
      })

    return result;
  }
})
