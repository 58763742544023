import { objToKeyValString } from "@/utils/object"
import { Job } from "@/store/jobs";
import { TNotificationIcons } from "@/components/notifications/types";
import { MIN_APPS_MULTICONTAINER_VERSION } from "@/store/cluster/composition";

export type K8sResource = {
  name?: string
  namespace?: string
  kind?: string
}

export class ResourceNotSupportedError extends Error {
  static __id__ = Symbol()
  public clusterName: string
  public resource: K8sResource

  constructor(clusterName: string, resource: K8sResource) {
    super(`Resource: ${objToKeyValString(resource)} is not supported.`)

    this.name = "ResourceNotSupportedError"
    this.clusterName = clusterName
    this.resource = resource
  }
}

export class ResourceNotFoundError extends Error {
  static __id__ = Symbol()
  public clusterName: string
  public resource: K8sResource

  constructor(clusterName: string, resource: K8sResource) {
    super(`Resource: ${objToKeyValString(resource)} was not found.`)

    this.name = "ResourceNotFoundError"
    this.clusterName = clusterName
    this.resource = resource
  }
}

export class JobIsTooShortError extends Error {
  static __id__ = Symbol()
  public clusterName: string
  public job: Job

  constructor(clusterName: string, job: Job) {
    super(`Graphs unavailable for short-lived Jobs, which ran for under 5 minutes.`)

    this.name = "JobIsTooShortError"
    this.clusterName = clusterName
    this.job = job
  }
}

export class PrometheusNotFoundError extends Error {
  static __id__ = Symbol()
  public clusterName: string

  constructor(clusterName: string, messageWithCluster = false, customMessage?) {
		if (messageWithCluster) {
			super(customMessage || `Unable to fetch metrics from Prometheus for cluster: ${clusterName}`)
		} else {
      super(customMessage || `To view graphs, a Prometheus integration must be configured`)
		}

    this.name = "PrometheusNotFoundError"
    this.clusterName = clusterName
  }
}

export class PrometheusRetentionPeriodOverError extends Error {
  static __id__ = Symbol()
  public clusterName: string

  constructor(clusterName: string) {
    super(`Prometheus retention period is too short to view graphs.`)

    this.name = "PrometheusRetentionPeriodOverError"
    this.clusterName = clusterName
  }
}

export class NoCloudRoutingError extends Error {
  static __id__ = Symbol()
  public clusterName: string
  public icon: TNotificationIcons

  constructor(clusterName: string, icon: TNotificationIcons) {
    super(` Enable cloud routing to view ${icon}s`)

    this.name = "NoCloudRoutingError"
    this.clusterName = clusterName
    this.icon = icon
  }
}

export class UpgradeToVersionError extends Error {
  static __id__ = Symbol()
  public clusterName: string
  public icon: TNotificationIcons

  constructor(clusterName: string, version: string, customMessage?: string, icon: TNotificationIcons = 'notification') {
		if (customMessage) super(customMessage)
    super(`To view graphs, upgrade Robusta to version ${version}.`)

    this.name = "UpgradeToVersionError"
	  this.icon = icon
    this.clusterName = clusterName
  }
}

export class PodsAvailability extends Error {
  static __id__ = Symbol()

  constructor() {
    super(`To view pods availability upgrade all your clusters to version ${MIN_APPS_MULTICONTAINER_VERSION}`)

    this.name = "PodsAvailability"
  }
}

export class AccountBillingEmpty extends Error {
  static __id__ = Symbol()

  constructor() {
    super(`AccountBilling not created yet`)

    this.name = "AccountBillingEmpty"
  }
}
